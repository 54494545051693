@import '../../../sass/variables';

.iconContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

@media only screen and (min-width: $mobileBreakPoint) {
  .iconContainer {
    justify-content: flex-start;
  }
}
