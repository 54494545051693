@import '../../../sass/variables';

.StripeElement {
  border-radius: 5px;
  border: 1px solid #9c9c9c;
  padding: 16px;
}

.StripeElement--focus {
  border: 1px solid $colorPurple;
}
