.container {
  display: flex;
}

.leftSection {
  width: 40%;
  padding: 64px 64px 64px 145px;
  border: 1px solid rgba(210, 206, 250, 0.4);
  min-height: 100vh;
}

.rightSection {
  padding: 64px;
  min-height: 100vh;
}
