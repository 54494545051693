@import '../../sass/variables';

label {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  color: $colorBlack;

  position: relative;
}

.icon {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

label input,
textarea {
  font-size: 16px;
  font-family: Poppins;
  line-height: 1.3;
  padding: 16px 24px;
  border: 1px solid rgba(210, 206, 250, 1);
  box-sizing: border-box;
  border-radius: 3px;
  color: $colorBlack;
  background-color: $colorBackground;
  width: 100%;
  margin-top: 8px;
}

label input:focus,
textarea:focus {
  border: 1px solid $colorPurple;
  outline: none;
}

label input:hover,
textarea:hover {
  border: 1px solid $colorPurple;
}

label input:disabled:hover,
textarea:disabled:hover {
  border: 1px solid #9c9c9c;
}

.error {
  border: 1px solid red;
}

.loading {
  position: absolute;
  right: 16px;
  top: 48px;
}

.errorMessage {
  font-size: $p;
  color: red;
  margin-top: 8px;
}

.textarea {
  height: 275px;
  margin-top: 8px;
  resize: none;
}
