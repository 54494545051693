@import '../../sass/variables';

.container {
  height: 100vh;
  width: 81px;
  position: fixed;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  border: 1px solid rgba(210, 206, 250, 0.4);
}

.logo {
  padding: 24px 24px 24px 24px;
  cursor: pointer;
}

.logo img {
  height: 33px;
  width: 33px;
}

.navItem {
  padding: 24px;
  cursor: pointer;
}

.navItem:hover {
  background-color: $colorPurple;
}

.navItem:hover svg path {
  stroke: $colorBackground;
}

.userPhoto {
  padding: 24px;
  position: relative;
}

.userPopover {
  position: absolute;
  top: -30px;
  left: 40px;

  padding: 24px;
  background: $colorBackground;
  border-radius: 5px;

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: none;
}

.userPhoto:hover .userPopover {
  display: inline;
}

.userPhoto img {
  height: 33px;
  weight: 33px;
  border-radius: 50%;
}

.active {
  background-color: $colorPurple;
}

.active svg path {
  stroke: $colorBackground;
}
