@import '../../../sass/variables';

.header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 32px;
}

.detailsForm {
  display: flex;
  flex-direction: column;
}

.detailsForm label {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  color: #9c9c9c;
}

.detailsForm label input {
  margin-top: 8px;
  font-size: 16px;
  font-family: Poppins;
  line-height: 1.3;
  padding: 16px;
  border: 1px solid #9c9c9c;
  box-sizing: border-box;
  border-radius: 3px;
  color: $colorBlack;
  background-color: $colorBackground;
}

.detailsForm label input:focus {
  border: 1px solid $colorPurple;
  outline: none;
}

.detailsForm label input:hover {
  border: 1px solid $colorPurple;
}

.detailsForm label textarea {
  margin-top: 8px;
  font-size: 16px;
  font-family: Poppins;
  line-height: 1.3;
  padding: 16px;
  border: 1px solid #9c9c9c;
  box-sizing: border-box;
  border-radius: 5px;
  color: $colorBlack;
  height: 200px;
  background-color: $colorBackground;
  -webkit-appearance: none;
}

.detailsForm label textarea:focus {
  border: 1px solid $colorPurple;
  outline: none;
}

.detailsForm label textarea:hover {
  border: 1px solid $colorPurple;
}

.buttonContainer {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}
