@import '../../../sass/variables';

.text {
  margin-bottom: 32px;
}

.text b {
  font-weight: 600;
}

.text a {
  color: $colorPurple;
  text-decoration: none;
}
