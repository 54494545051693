@import '../../../sass/variables';

.inputSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.header {
  font-size: $h3;
  font-weight: 600;
  margin-bottom: 32px;
}
