@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Poppins:wght@300;600&family=Playfair+Display:wght@800&display=swap');
@import './sass/variables';

body,
html {
  height: 100%;
  margin: 0;
  font-weight: 300;
  font-family: Poppins;
  font-size: 16px;
  color: $colorBlack;
  background-color: $colorBackground;
  line-height: 1.5;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

#root {
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='text'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
