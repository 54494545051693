@import '../../../sass/variables';

.header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 32px;
}

.timezoneContainer {
  margin-top: 32px;
}

.seperator {
  margin: 64px 0px;
  border: 0.5px solid $colorRed;
}

.buttonContainer {
  margin-top: 64px;
  display: flex;
  justify-content: center;
}
