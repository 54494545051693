@import '../../../../sass/variables';

.weeklyAvailability {
  &:focus {
    outline: none;
  }
}

.dayBadges {
  display: inline-flex;

  margin-bottom: 32px;
}

.dayBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 24px;
  height: 24px;
  border: 1px solid $colorPurple;
  border-radius: 50%;
  outline: none;

  background-color: $colorBackground;
  color: $colorPurple;

  margin-right: 12px;

  &:hover {
    cursor: pointer;
  }
}

.dayBadgeChosen {
  background-color: $colorPurple;
  color: white;
}

.dayAvailability {
  margin-bottom: 32px;

  display: flex;
  align-items: center;
  justify-content: start;

  & > * {
    margin-right: 32px;
  }
}

.dayLabel {
  min-width: 48px;
}

.actions {
  display: inline-flex;
  justify-content: start;
  align-items: center;
  text-align: center;
}

.actionButton {
  border: none;
  outline: none;
  background-color: $colorBackground;
  font: inherit;
  font-size: 12px;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }

  &.add {
    color: $colorPurple;
  }

  &.delete {
    color: #f30404;
  }
}
