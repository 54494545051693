@import '../../../../sass/variables';

.header {
  font-weight: 600;
  font-size: 18px;
}

.timeList {
  display: flex;
  flex-direction: column;
}

.time {
  margin-top: 32px;
  padding: 16px 32px;
  border-radius: 10px;
  border: 1px solid #695df1;
  background: none;
  cursor: pointer;

  display: flex;
  align-items: flex-start;
  font-style: Poppins;
  font-size: 16px;
  font-weight: 300;
  color: $colorBlack;
}

.time:hover {
  background: #f0eefd;
}

.time:focus {
  outline: none;
}

.selected {
  background: $colorPurple;
  color: $colorBackground;
}

.selected:hover {
  background: $colorPurple;
  color: $colorBackground;
}
