@import 'sass/variables';

.button {
  padding: 16px;
  border-radius: 10px;
  color: $colorBackground;
  background: $colorPurple;
  border: none;
  font-family: 'Poppins';
  font-size: 16px;

  cursor: pointer;
}

.button:hover {
  background: $colorPurpleDarker;
}

.button:focus {
  outline: none;
}

.button:disabled {
  cursor: default;
  background-color: #9c9c9c;
}

.maxWidth {
  width: 100%;
}
