@import '../../sass/variables';

.profileContainer {
  margin: 0px 0px 100px 0px;
}

.profileCard {
  margin: 0px 20px;
  position: relative;
}

.banner {
  position: absolute;
  left: -20px;
  width: 100vw;
  height: 250px;

  -webkit-clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
}

.poweredByProfound {
  position: absolute;
  right: -20px;
  top: 0px;
}

.profilePhotoContainer {
  position: relative;
  padding-top: 170px;

  display: flex;
  justify-content: center;
}

.profilePhoto {
  border-radius: 50%;
  width: 128px;
  height: 128px;
}

.nameAndButtonContainer {
  display: flex;
  flex-wrap: wrap;

  margin-top: 24px;

  justify-content: center;
}

.buttonContainer {
  margin-top: 32px;
  width: 100%;
}

.profileNameAndTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profileNameAndTitleContainer h1 {
  margin: auto;
  font-family: Playfair Display;
  font-weight: 800;
  font-size: 30px;
  margin-bottom: 16px;
}

.profileNameAndTitleContainer h2 {
  margin: 0px;
  font-family: Poppins;
  font-weight: 300;
  font-size: 16px;
}

.buttonContentContainer {
  display: flex;
  justify-content: center;
}

.buttonContentContainer img {
  margin-right: 8px;
}

.buttonContentContainer p {
  margin: 0px;
  font-size: 16px;
}

.buttonContentContainer p strong {
  font-weight: 600;
}

.highlightContainer {
  position: relative;
  z-index: 1;
}

.highlightYellow {
  background: $colorYellow;
  width: calc(100% + 8px);
  height: calc(100% + 4px);
  position: absolute;

  top: -2px;
  left: -4px;

  z-index: -1;
}

.price {
  font-size: 26px;
  font-weight: 600;
}

.pricing {
  display: flex;
  justify-content: center;
}

.seperator {
  margin: 32px 0px 64px;
  border: 0.5px solid $colorRed;
}

@media only screen and (min-width: $mobileBreakPoint) {
  .profileContainer {
    display: flex;
    justify-content: center;

    margin: 100px 0px;
  }

  .poweredByProfound {
    right: 0px;
  }

  .profilePhotoContainer {
    padding-top: 106px;
    justify-content: flex-start;
  }

  .nameAndButtonContainer {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .buttonContainer {
    margin: 0px;
    width: auto;
  }

  .profileNameAndTitleContainer {
    max-width: 300px;
    align-items: flex-start;
  }

  .profileNameAndTitleContainer h1 {
    margin: 0px 0px 16px 0px;
  }

  .profileCard {
    box-sizing: border-box;
    width: 600px;
    margin: 0px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 64px 32px;
  }

  .banner {
    max-width: 600px;
    left: 0px;
    top: 0px;

    border-radius: 10px 10px 0px 0px;
  }
}
