$colorPurple: #695df1;
$colorPurpleDarker: #5e53d8;
$colorBackground: #fefaf7;
$colorGrey: #97a0af;
$colorBlack: #312f2e;
$colorRed: #ff8c8c;
$colorYellow: #fdea6a;

$mobileBreakPoint: 600px;

$h1: 62px;
$h2: 52px;
$h3: 20px;
$h4: 14px;
$p: 12px;

:export {
  colorPurple: $colorPurple;
  colorBackground: $colorBackground;
  colorGrey: $colorGrey;
  colorBlack: $colorBlack;
  colorYellow: $colorYellow;
  colorRed: $colorRed;
  mobileBreakPoint: $mobileBreakPoint;
  h1: $h1;
  h2: $h2;
  h3: $h3;
  h4: $h4;
  p: $p;
}
