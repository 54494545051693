@import '../../../../sass/variables';

.container {
  display: flex;
  margin-top: 8px;

  align-items: flex-start;
}

.avatar {
  height: 110px;
  width: 110px;
  margin-right: 16px;
}

.fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.uploadButton {
  display: flex;
  padding: 16px;
  align-items: center;
  width: 100%;

  background-color: $colorPurple;
  margin-bottom: 0px;
  border-radius: 5px;
  color: $colorBackground;
  cursor: pointer;
}

.uploadButton:hover {
  background-color: $colorPurpleDarker;
}
