@import '../../../sass/variables';

.header {
  font-size: $h3;
  font-weight: 600;
  margin-bottom: 8px;
}

.subheader {
  font-size: 12px;
  margin-bottom: 32px;
}

.smallSection {
  display: flex;
  margin-bottom: 32px;
}

.smallLine {
  background: rgba(210, 206, 250, 0.4);
  border-radius: 2px;
  width: 25%;
  height: 8px;
  margin-right: 32px;
  box-sizing: border-box;
}

.smallLine2 {
  background: rgba(210, 206, 250, 0.4);
  border-radius: 2px;
  width: 10%;
  height: 8px;
  box-sizing: border-box;
}

.largeSection {
  background: $colorBackground;
  box-shadow: 0px 0px 4px rgba(17, 45, 78, 0.25);
  border-radius: 3px;
  width: 100%;
  padding: 8px 24px;
  margin-bottom: 32px;

  box-sizing: border-box;
}

.largeLine {
  background: rgba(210, 206, 250, 0.4);
  border-radius: 2px;
  width: 100%;
  height: 8px;
  box-sizing: border-box;
  margin: 16px 0px;
}

.largeLineHalf {
  background: rgba(210, 206, 250, 0.4);
  border-radius: 2px;
  width: 60%;
  height: 8px;
  box-sizing: border-box;
  margin: 16px 0px;
}

.mediumBlock {
  background: rgba(210, 206, 250, 0.4);
  border-radius: 3px;
  width: 30%;
  height: 32px;
  box-sizing: border-box;
  margin: 16px 0px;
}
