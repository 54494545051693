@import '../../../sass/variables';

.header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 32px;
}

.subheader {
  color: #9c9c9c;
  margin-bottom: 32px;
}

.time {
  margin-bottom: 32px;
  padding: 16px 32px;
  border-radius: 10px;
  border: 1px solid $colorPurple;
  display: flex;
  align-items: flex-start;
  font-style: Poppins;
  font-size: 16px;
  font-weight: 300;

  background: $colorPurple;
  color: $colorBackground;
}

.dueNowRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

p {
  margin: 0px;
}

.totalRow {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-top: 32px;

  margin-bottom: 64px;
}

.buttonContainer {
  margin-top: 64px;
  display: flex;
  justify-content: center;
}
